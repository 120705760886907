
























































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import ButtonBase from '@/components/atoms/ButtonBase.vue'
import CheckboxSquare from '@/components/atoms/CheckboxSquare.vue'
import RadioToggle from '@/components/atoms/RadioToggle.vue'
import RadioBase from '@/components/atoms/RadioBase.vue'

@Component({
  components: {
    ButtonBase,
    CheckboxSquare,
    RadioToggle,
    RadioBase,
  },
})
export default class TableBase extends Vue {
  private small = false

  private striped = false

  private hover = true

  private headVariant = null

  private bordered = false

  private borderless = false

  @Prop()
  perPage?: number

  @Prop()
  items!: []

  @Prop()
  fields!: []

  @Prop()
  buttons?: []

  @Prop()
  customButtons?: []

  @Prop()
  checkboxs?: []

  @Prop()
  radios?: []

  @Prop()
  showHeaderCheckbox?: boolean

  @Prop()
  toggles?: []

  @Prop()
  multipleLines?: []

  @Prop({ default: 0 })
  number?: number

  @Prop()
  links?: []

  @Prop({ default: 0 })
  count?: number

  @Prop({ default: 1 })
  currentPage?: number

  @Prop({ default: false })
  stickyHeader?: boolean

  @Prop()
  radioName?: string

  @Prop()
  radioChecked?: number

  @Watch('items')
  onItemsChanged(newItems: any) {
    if (!this.checkboxs || !this.showHeaderCheckbox) return
    this.checkboxs.forEach((key: string) => {
      this.switchHeadCheckbox(key, newItems)
    })
  }

  private headerCheckboxChecked = this.getHeaderCheckboxChecked()

  private changeAllCheckbox(key: string, value: boolean) {
    this.items.forEach((item: any) => {
      item[key].checked = value
    })
  }

  private changeHeadCheckbox(key: string) {
    if (!this.showHeaderCheckbox) return
    this.switchHeadCheckbox(key, this.items)
  }

  private switchHeadCheckbox(key: string, items: any[]) {
    const itemChecked = items.map((item: any) => {
      return item[key].checked
    })
    this.headerCheckboxChecked[key] = !itemChecked.includes(false)
  }

  private getHeaderCheckboxChecked() {
    const headerCheckboxChecked: { [key: string]: boolean } = {}
    if (!this.checkboxs || !this.showHeaderCheckbox) return headerCheckboxChecked
    this.checkboxs.forEach((key: string) => {
      headerCheckboxChecked[key] = false
    })
    return headerCheckboxChecked
  }

  private getCell(value: string): string {
    return `cell(${value})`
  }

  private getHeader(value: string): string {
    return `head(${value})`
  }

  private checkVariant(value: { [key: string]: string }): string {
    if (value.variant) {
      return value.variant
    }
    return 'outline-primary'
  }

  private checkurl(value: string): string | null {
    if (value) {
      return value
    }
    return null
  }

  // eslint-disable-next-line
  private checkclickButton(data: any, link: any): string | null {
    if (link.onclick) {
      // 変数の指定があればその変数を渡す TODO: 複数変数対応が必要？
      if (link.variable) {
        return link.onclick(link.variable)
      }
      return link.onclick(this.number, data.index)
    }
    return null
  }

  // eslint-disable-next-line
  private checkclick(data: any): string | null {
    if (data.value.onclick) {
      // 変数の指定があればその変数を渡す TODO: 複数変数対応が必要？
      if (data.value.variable) {
        return data.value.onclick(data.value.variable)
      }
      return data.value.onclick(this.number, data.index)
    }
    return null
  }

  // eslint-disable-next-line
  private checkcchangeRadio(data: any, checked: boolean): Function | null {
    if (data.value.onChange && data.value.onChange.func) {
      return data.value.onChange.func(data.value.onChange.funcParams, checked)
    }

    return null
  }

  /**
   * ページネーション
   */
  private paginate(page: number): void {
    this.$emit('paginate', page)
  }

  /**
   * ラジオボタン
   */
  private onRadioChange(value: string | number): void {
    this.$emit('inputRadio', value)
  }
}
